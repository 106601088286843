import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import Clipboard from '@ryangjchandler/alpine-clipboard'

window.Alpine = Alpine

Alpine.plugin(persist)
Alpine.plugin(Clipboard)

Alpine.magic('clipboard', () => {
    return subject => navigator.clipboard.writeText(subject)
})

Alpine.start();

require('./bootstrap');

// require('alpinejs');


// toggle row in event table
$('.accordian-body').on('show.bs.collapse', function () {
    $(this).closest("table")
        .find(".collapse.in")
        .not(this)
        //.collapse('toggle')
})

// add row for another code in multiple create coupon
if (window.location.href.indexOf("/coupon/create-multiple") > -1) {
var i = 0;
$('#add-row').on('click', function () {
    ++i;
    $('#add-rows').append('<div class="form-group row"><label for="code" class="col-md-4 col-form-label text-md-right">Kód:</label><div class="d-flex col-md-6"><input id="code" type="text" class="form-control" name="code['+i+'][subject]" required autocomplete="code" autofocus><a id="generateCode" class="inline-block !py-2 !px-4 border rounded-md hover:bg-slate-700 hover:text-white text-nowrap" onclick="generateUniqueCode()">Generuj kód</a></div></div>')
})}